<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="2" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title">收货地址 / <span>新增收货地址</span></h1>
                </div>

                <div class="shop_form mt30">
                    <el-form ref="form" :model="form" :rules="rules" label-width="94px" size="small">
                        <el-form-item label="地址信息：" class="mb32" prop="province">
                            <el-cascader :options="regionData" v-model="address" @change="addressChange" placeholder="请选择省/市/区" :props="{ value: 'label' }" class="fullInput"></el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址：" class="mb32" prop="address">
                            <el-input v-model="form.address" placeholder="请填写详细地址" class="fullInput"></el-input>
                        </el-form-item>
                        <el-form-item label="邮政编码：" class="mb32" prop="postcode">
                            <el-input v-model="form.postcode" placeholder="请填写邮政编码" class="fullInput"></el-input>
                        </el-form-item>
                        <el-form-item label="收货人姓名：" class="mb32" prop="name">
                            <el-input v-model="form.name" placeholder="长度不超过20个字符" class="fullInput"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码：" class="mb32" prop="phone">
                            <el-select v-model="region" placeholder="请选择省/市/区/街道" class="halfInput">
                                <el-option label="中国 +86" value="86"></el-option>
                            </el-select>
                            <el-input v-model="form.phone" placeholder="请填写11位数的手机号码" class="phoneInput"></el-input>
                        </el-form-item>
                        <el-form-item class="mb12">
                            <el-checkbox v-model="form.isDefault" :true-label="1" :false-label="2" label="设置为默认地址" name="type"></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm('form')">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="shop_address_list mt18">
                    <div class="head">
                        <i class="el-icon-warning-outline"></i>
                        <span>已保存{{listData.length}}条地址，还能保存{{20 - listData.length}}条地址</span>
                    </div>

                    <el-table :data="listData" style="width: 962px" border>
                        <el-table-column prop="name" label="收货人" width="84" align="center"></el-table-column>

                        <el-table-column prop="date" label="所在地区" width="160" align="center">
                            <template slot-scope="scope">
                                {{scope.row.province}}{{scope.row.city}}{{scope.row.area}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="address" label="详细地址" align="center"></el-table-column>

                        <el-table-column prop="postcode" label="邮政编码" width="100" align="center"></el-table-column>

                        <el-table-column prop="phone" label="手机号码" width="122" align="center"></el-table-column>

                        <el-table-column prop="date" label="操作" width="120" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="delData(scope.row.id)">删除</el-button>
                                <el-button type="text" @click="toEditAddress(scope.row)">编辑</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column prop="date" width="110" align="center">
                            <template slot-scope="scope">
                                <span class="default" v-if="scope.row.isDefault === 1">默认地址</span>
                                <el-button type="text" class="default" v-else @click="setDefault(scope.row.id)">设置为默认地址</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>

        <el-dialog class="popup_dialog" title="编辑地址" :visible.sync="isPopupEdit" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formEdit" :model="formEdit" :rules="rules" label-position="top" size="small">
                <el-form-item label="地址信息：" prop="province">
                    <el-cascader :options="regionData" v-model="addressEdit" @change="addressChangeEdit" placeholder="请选择省/市/区" :props="{ value: 'label' }"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address">
                    <el-input v-model="formEdit.address" placeholder="请填写详细地址"></el-input>
                </el-form-item>
                <el-form-item label="邮政编码：" prop="postcode">
                    <el-input v-model="formEdit.postcode" placeholder="请填写邮政编码"></el-input>
                </el-form-item>
                <el-form-item label="收货人姓名：" prop="name">
                    <el-input v-model="formEdit.name" placeholder="长度不超过20个字符"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone">
                    <el-select v-model="region" style="width: 20%" class="mrb4">
                        <el-option label="中国 +86" value="86"></el-option>
                    </el-select>
                    <el-input v-model="formEdit.phone" placeholder="请填写11位数的手机号码" style="width: 76%"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="formEdit.isDefault" :true-label="1" :false-label="2" label="设置为默认地址" name="type"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="submitFormEdit('formEdit')">保 存</el-button>
                    <el-button type="default" @click="isPopupEdit = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
  import {regionData} from "element-china-area-data";
  import {getAddress, postAddress, putAddress, delAddress} from '../../../api/shop/address'
  const userMenu = () => import('../../../components/layout/shop/userMenu')
  export default {
    name: "shopOrderDetail",
    data () {
      return {
        form: {
          province: '',
          city: '',
          area: '',
          address: '',
          postcode: '',
          name: '',
          phone: '',
          isDefault: 1
        },
        formEdit: {
          province: '',
          city: '',
          area: '',
          address: '',
          postcode: '',
          name: '',
          phone: '',
          isDefault: 1
        },
        rules: {
          province: [
            { required: true, message: '请选择省/市/区', trigger: 'change' }
          ],
          address: [
            { required: true, message: '请填写详细地址', trigger: 'blur' }
          ],
          postcode: [
            { required: true, message: '请填写邮政编码', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请填写收货人姓名', trigger: 'blur' },
            { max: 20, message: '收货人姓名长度不超过20个字符', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请填写手机号码', trigger: 'blur' },
            { pattern: '^1[3456789]\\d{9}$', message: '请填写11位数的手机号码', trigger: 'blur'}
          ],
        },
        region: '86',
        listData: [],
        address: [],
        addressEdit: [],
        editId: '',
        regionData: regionData,
        submitLoading: false,
        isPopupEdit: false
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getList()
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getAddress().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      addressChange (e) {
        this.form.province = e[0]
        this.form.city = e[1]
        this.form.area = e[2]
      },
      addressChangeEdit (e) {
        this.formEdit.province = e[0]
        this.formEdit.city = e[1]
        this.formEdit.area = e[2]
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.listData.length === 20) {
              this.$message({
                message: '已达保存最大值',
                showClose: true,
                type: 'warning'
              })
              return false
            }

            this.submitLoading = true
            postAddress({
              data: this.form
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '地址添加成功',
                  showClose: true,
                  type: 'success'
                })
                this.getList()
                this.$refs['form'].resetFields()
                this.address = []
              }
            }).catch(() => {
              this.submitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      toEditAddress (item) {
        this.formEdit.province = item.province
        this.formEdit.city = item.city
        this.formEdit.area = item.area
        this.formEdit.address = item.address
        this.formEdit.postcode = item.postcode
        this.formEdit.name = item.name
        this.formEdit.phone = item.phone
        this.formEdit.isDefault = item.isDefault
        this.addressEdit = [item.province, item.city, item.area]
        this.editId = item.id
        this.isPopupEdit = true
      },
      submitFormEdit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            putAddress(this.editId, {
              data: this.formEdit
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '地址编辑成功',
                  showClose: true,
                  type: 'success'
                })
                this.isPopupEdit = false
                this.getList()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      delData (id) {
        this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delAddress(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '地址删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      setDefault (id) {
        this.$confirm('此操作将设置该地址为默认地址, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          putAddress(id, {
            data: {
              isDefault: 1
            }
          }).then(res => {
            this.submitLoading = false
            if (res.code === 0) {
              this.$message({
                message: '默认地址设置成功',
                showClose: true,
                type: 'success'
              })
              this.getList()
            }
          }).catch(() => {
            this.submitLoading = false
          })
        }).catch(() => {});
      }
    },
    components: {
      userMenu
    }
  }
</script>
