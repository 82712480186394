import {request} from '@/utils/request'

// 地址列表
export function getAddress(data) {
  return request('GET','/shop/address', data, true)
}
// 地址添加
export function postAddress(data) {
  return request('POST','/shop/address', data, true)
}
// 地址编辑
export function putAddress(id, data) {
  return request('PUT','/shop/address/' + id, data, true)
}
// 地址删除
export function delAddress(id) {
  return request('DELETE','/shop/address/' + id, '', true)
}
